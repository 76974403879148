import React, {useState} from "react";
import {useUser} from "../selector";
import HeaderBar from "../components/HeaderBar";
import ajaxClient from "../services/ajaxClient";
import Container from "@material-ui/core/Container";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getOffersQueryLink, getQuery, parseQuery} from "../services/localizationService";
import SearchBar from "../components/SearchBar";
import Footer from "./Footer";
import Background from '../assets/bg.png';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loader from "../components/Loader";
import OffersList from "../components/Offers/OffersList";
import CatalogCategoriesFilter from "../components/Catalogs/CatalogCategoriesFilter";
import PowerFilter from "../components/Catalogs/PowerFilter";
import MonthlyPriceFilter from "../components/Catalogs/MonthlyPriceFilter";
import CatalogTagsFilter from "../components/Catalogs/CatalogTagsFilter";
import {assetsPath} from "../services/mainService"; // Import using relative path

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        marginBottom: '15px'
    },
    mapContainer: {
        /*backgroundColor: 'red',*/
        position: 'relative',
        '& $items': {
            /*backgroundColor: 'green',*/
            padding: 16,
            [theme.breakpoints.up('lg')]: {
                maxWidth: '808px'
            },
        },
        '& $map': {
            [theme.breakpoints.down('lg')]: {
                top: 0,
                position: 'fixed',
                width: '100%',
                height: '100%'
            },
            [theme.breakpoints.up('lg')]: {
                width: 'calc(100% - 840px)',
                inset: '0px 0px 0px',
                position: 'absolute',
                top: 0,
                left: 'auto !important',
            },
            /*backgroundColor: 'grey',*/
        },

        '& $mapBox': {
            [theme.breakpoints.up('lg')]: {
                height: '100vh',
                paddingtop: '80px',
                marginTop: '-80px',
                position: 'sticky',
                top: '0px',
            },
            /*backgroundColor: 'yellow',*/
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 100%",
        }
    },
    filters: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
    },

    mapSwitchFilter: {
        [theme.breakpoints.down('md')]: {
            position: 'fixed',
            zIndex: '10001',
            background: 'white',
            width: '100%',
            left: '0px',
            padding: '15px',
            top: '64px',
        },
    },

    map: {
        backgroundColor: 'gray',
        width: '100%',
        height: '100%',
    },

    items: {},
    mapBox: {},
    typo: {
        marginTop:'24px',
        textAlign: "center",
        fontSize:'16px',
        color:'#757575',
    },
    box: {
        border: '1px solid #DDDDDD',
        borderRadius: '6px',
    },
    img :{
        maxWidth:"300px",
        maxHeight:"300px",
        margin:"auto",
        display:"block",
        padding:"24px 0px 0px",
    }
}));

function Offers(props) {
    const classes = useStyles();

    const query = props.query || '';

    console.log('[query]', query);
    console.log('[parseQuery]', parseQuery(query));

    const user = useUser();

    const [offers, setOffers] = useState(null);
    const [category, setCategory] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const [showMap, setShowMap] = useState(parseQuery(query).showMap);
    const [address, setAddress] = useState(parseQuery(query).address);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    React.useEffect(() => {
        if (formValues) {

            async function fetchOffers() {
                const response = await ajaxClient().get(`offers?` + getQuery(formValues));
                setOffers(response.data.data);
            }

            async function fetchCategory() {
                if (formValues.category) {
                    const response = await ajaxClient().get(`categories?` + getQuery(formValues) + "&first");
                    setCategory(response.data.data);
                } else {
                    setCategory(null);
                }
            }

            fetchOffers();
            fetchCategory();
        }
    }, [formValues]);

    function handleClick(event) {
        event.preventDefault();
    }

    function handleMap(event) {
        setShowMap(event.target.checked);
    }

    function handleAddressChange(address) {
        setAddress(address);
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/catalogs" titleBar=""/>
            <SearchBar onAddressChange={handleAddressChange} type={"offers"} address={address}/>
            <Container maxWidth={"lg"} className={classes.container}>
                <Box className={classes.items}>
                    <Box pt={2} pb={2}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" onClick={handleClick}>
                                Strona główna
                            </Link>

                            <Link
                                color="textPrimary"
                                href="/offers"
                                to="/offers"
                                /*onClick={() => navigate("/catalogs")}*/
                            >
                                Oferty
                            </Link>

                            {category && category.name && (
                                <Link
                                    color="textPrimary"
                                    href={getOffersQueryLink(formValues, {'category': category.name})}
                                    to={getOffersQueryLink(formValues, {'category': category.name})}
                                    /*onClick={() => navigate(`/${country}/${city}/catalogs/category/${category.slug}`)}*/
                                    aria-current="page"
                                >
                                    {category.name}
                                </Link>)
                            }

                        </Breadcrumbs>

                        <Box mt={2}>
                            <Typography component={"h1"} variant={"h4"}>Oferty w Twoim mieście</Typography>
                        </Box>
                    </Box>

                    <Grid item xs={12} sm={12}>
                        <Box className={classes.filters} display="flex" justifyContent={"start"}>
                            <Box pr={2}>
                                <CatalogCategoriesFilter query={query} type={"offers"}/>
                            </Box>

                            {category && category.type === 'pv' && (
                                <Box pr={2}>
                                    <PowerFilter query={query} type={"offers"}/>
                                </Box>
                            )}

                            {category && category.type === 'pv' && (
                               <Box pr={2}>
                                    <MonthlyPriceFilter query={query} type={"offers"}/>
                                </Box>
                            )}
                        </Box>
                        <Box pr={2}>
                            <CatalogTagsFilter query={query} type={"offers"} category={category}/>
                        </Box>
                    </Grid>

                    {/*<Box display={"flex"} justifyContent={"space-between"} className={classes.filters}>
                        <CatalogCategories query={query} type={"offers"}/>
                        <Box py={2}>

                        </Box>
                    </Box>*/}

                    {!offers && (
                        <Loader>Trwa ładowanie...</Loader>
                    )}

                    {offers && category && (
                        <OffersList category={category} menuItems={offers}/>
                    )}

                    {!category && (
                        <Box mt={5} class={classes.box}  display={"flex"} justifyContent={"center"}>
                            <div>
                                <Typography component="p" className={classes.typo}>
                                    Aby odnaleźć interesujące oferty, wybierz kategorię
                                </Typography>
                                <img src={assetsPath('noServices.png')} className={classes.img}/>
                            </div>
                        </Box>
                    )}
                </Box>
            </Container>

            <Footer/>
        </div>
    );
}

export default Offers;
