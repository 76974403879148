const REACT_APP_SITE = process.env.REACT_APP_SITE;

let jakieozeLangs = {
    headerBarCatalogsName: 'Instalatorzy',
    searchBar:'Najlepsza porównywarka <b>ofert OZE</b> w Polsce',
    searchBar2:'Odkryj i wybierz najlepszą ofertę w okolicy',
    catalogsTypo:'Instalatorzy w Twoim pobliżu',
    loginText:'Chcesz do nas dołączyć ?',
    loginText2:'Zarejestruj się jako instalator',
    loginText3:'Załóż konto',
    nameCatalog:'Instalatorzy',
    namelinkCatalog:'Instalatorzy'
}

let paramedycyLangs = {
    headerBarCatalogsName: 'Kuracje',
    searchBar: 'Szukaj kuracji, zabiegów w całej Polsce',
    searchBar2: 'Odkryj i wybierz najlepszą ofertę w okolicy',
    catalogsTypo: 'Firmy w Twoim mieście',
    loginText:'Chcesz do nas dołączyć ?',
    loginText2:'Zarejestruj się jako ',
    loginText3: 'Załóż konto',
    nameCatalog:'Kontakt',
    namelinkCatalog:'Terapeuci'
}

export const LANGS = REACT_APP_SITE === 'jakieoze' ? jakieozeLangs : paramedycyLangs;
