import React from "react";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {PhotoCamera} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
import {FONTS} from "../Theme";


const useStyles = makeStyles((theme) => ({
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    root: {
        marginTop: theme.spacing(4),
    },
    subtitle: {
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        opacity: '0.59',
        marginTop: '5px',
        marginBottom: '5px',
        textAlign: 'center'
    }
}));

const FileUploader = props => {
    const classes = useStyles();
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        props.onChange(event);
    };

    console.log('[FileUploader]', props.image);

    return (
        <div className={classes.root}>
            {props.image ? (
                <CardMedia
                    onClick={handleClick}
                    image={props.image}
                    title={props.title}
                    className={classes.media}

                />
            ) : (
                <Button
                    fullWidth={true}
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={handleClick}
                    startIcon={<PhotoCamera />}>
                    Dodaj zdjęcie
                </Button>
            )}

            {props.subtitle && (
                <Typography color="secondary" component={"p"} className={classes.subtitle}>{props.subtitle}</Typography>
            )}

            <input type="file"
                   name={props.name}
                   accept={props.accept}
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{display: 'none'}}
            />
        </div>
    );
};
export default FileUploader;
