import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Background from '../assets/bg.png'; // Import using relative path
import Typography from "@material-ui/core/Typography";
import GeoAutoCompleteInput from "../components/GeoAutoCompleteInput";
import Container from "@material-ui/core/Container";
import {navigate} from "@reach/router";
import {getOffersQueryLink, getQueryLink} from "../services/localizationService";
import {useDispatch} from "react-redux";
import {LANGS} from "../Langs";
import {COLORS, FONTS} from "../Theme";
import {assetsPath} from "../services/mainService";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({



    page: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        maxHeight: '318px',
        height: '318px'
    },
    pageWrapper: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: `url(${assetsPath('search/background.png')})`,

        [theme.breakpoints.down('md')]: {
            background: `url(${assetsPath('search/background.png')})`,
        }
    },
    pageOverlay: {
        width: "100%",
        height: "100%",
        /*backgroundColor: "rgba(0,0,0,0.46)",*/
        zIndex: 0,
        position: "absolute",
        maxHeight: '318px'
    },

    pageOverlayDown:{
        backgroundColor: COLORS.primary,
        height:"29px",
        width:"100%",
        position:"absolute",
        bottom:"0"
    },
    pageContainer: {
        zIndex: 1,
        position: "relative"
    },
    pageIntro: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
    },

    search: {
        zIndex: 1001,
        height: '100%'
    },

    geoInput: {
        minWidth: '500px',
        maxWidth: '500px',
        width: '100%',
        margin: 'auto',

        [theme.breakpoints.down('sm')]: {
            minWidth: '300px',
            maxWidth: '100%',
        },

        [theme.breakpoints.up('md')]: {
            maxWidth: '300px',
            with: '300px'
        },
    },

    h2: {
        font: 'normal normal bold 42px ' + FONTS.fontFamily,
        color: COLORS.secondary,
        letterSpacing: '1.13px',
        marginBottom: '20px',
        lineHeight: '150%',

        [theme.breakpoints.down('sm')]: {
            marginTop: '40px',
            font: 'normal normal bold 20px ' + FONTS.fontFamily,
        },
        '& > b': {
            color: COLORS.primary
        }
    },
    h3: {
        font: 'normal normal normal 28px ' + FONTS.fontFamily,
        fontWeight: 'bold',
        letterSpacing: '0.15px',
        color: COLORS.secondary,
        marginBottom: '20px',

        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            font: 'normal normal normal 16px ' + FONTS.fontFamily,
        },
        '& > b': {
            color: COLORS.primary
        }
    },

    h4: {
        font: 'normal normal normal 16px ' + FONTS.fontFamily,
        fontWeight: 400,
        letterSpacing: '0.15px',
        lineHeight: '150%',
        color: COLORS.secondary,
        marginBottom: '20px',

        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            font: 'normal normal normal 16px ' + FONTS.fontFamily,
        },
        '& > b': {
            color: COLORS.primary
        }
    },
    icon:{
    padding:'16px'
    }

}));

export default function SearchBar({onAddressChange, onSearch, type = 'catalog', address = null, category = null}) {
    const classes = useStyles({category});
    const dispatch = useDispatch();

    console.log('address', address);

    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);
    const [filterChange, setFilterChange] = useState(address ? false : false);

    function handleInputAddressChange(event, data) {
        submit({
            ...formValues,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,

            city: data.city,
            state: data.state,
            country: data.country
        });
    }

    function handleClick(event) {
        submit(formValues);
    }

    function onFilterClick() {
        setFilterChange(true);
    }

    function submit(data) {
        if (onSearch) {
            onSearch(data);
        } else {
            if (type == 'catalog') {
                navigate(process.env.PUBLIC_URL + getQueryLink(data));
            } else {
                navigate(process.env.PUBLIC_URL + getOffersQueryLink(data));
            }

            onAddressChange(data.address);
            setFilterChange(false);
        }
    }

    return (
        <div>
            <div className={classes.page}>
                <div className={classes.pageWrapper}>
                    {/*<div className={classes.pageOverlay}>
                        <div className={classes.pageOverlayDown}></div>
                    </div>*/}

                    <Container component="main" maxWidth="lg">
                        <Grid container className={classes.pageContainer}>
                            <Grid item xs={12} className={classes.pageIntro}>

                                <div className={classes.search}>
                                    {(!filterChange && address) && (
                                        <div className={classes.h3}>
                                            Najlepsze oferty <b>{address}</b>
                                            <br/><br/>
                                            <Button onClick={onFilterClick} color="primary" variant="contained" autoFocus size="large">
                                                Zmień
                                            </Button>
                                        </div>
                                    )}

                                    {(filterChange || !address) && (
                                        <div>
                                            <Typography className={classes.h2} dangerouslySetInnerHTML={{ __html: LANGS.searchBar }} ></Typography>
                                            <img src={assetsPath('iconSmall.png')} className={classes.icon}/>
                                            <Typography className={classes.h4}>
                                                {LANGS.searchBar2}
                                            </Typography>

                                            <div className={classes.geoInput}>
                                                <GeoAutoCompleteInput
                                                    label={'Podaj adres'}
                                                    defaultValue={formValues.address || (address ? address : '')}
                                                    onChange={handleInputAddressChange}
                                                    error={false}
                                                    type={'home'}
                                                />
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </div>
    );
}
