import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {hasError} from "../../../services/validators";

import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ajaxClient from "../../../services/ajaxClient";
import {navigate} from "@reach/router";
import {getQueryLink} from "../../../services/localizationService";
import {updateMenuCollection} from "../../../services/catalogService";
import MenuItemFields from "./Partial/Pv/MenuItemFields";
import ProductFields from "./Partial/Pv/ProductFields";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    file: {
        width: 200,
        height: 150
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    h1:{
      fontSize:'20px',
    },
    formControl: {
        width: '100%'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        color: 'red',
        marginTop: 15
    }
}));

function EditMenuCollectionForm({menuCollection, onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);
    const [categories, setCategories] = useState([]);

    React.useEffect(() => {
        if (menuCollection) {
            console.log('[menuCollection]', menuCollection);
            setFormValues(menuCollection);
        }
    }, [menuCollection]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);


    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleInputCategoryChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let selectedCategory = findCategory(target.value);

        setFormValues({
            ...formValues,
            [name]: value,
            category: selectedCategory
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        console.log('SUBMIT', data);

        updateMenuCollection(data).then(response => {
            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    function handleMenuItemsFieldsChange(updatedFormValues) {
        setFormValues({
            ...formValues,
            menu_items: updatedFormValues
        });
    }

    function handleMenuItemsProductChange(productType, producer, product) {
        let currentProducts = formValues.products;

        console.log('[CHANGED 1]', producer, product);

        if (producer) {
            currentProducts[productType - 1] = {
                ...currentProducts[productType - 1],
                producer: producer,
                producer_id: producer ? producer.id : null,
                product: product,
                product_id: product ? product.id : null
            };
        } else {
            currentProducts[productType - 1] = {
                ...currentProducts[productType - 1],
                product: product,
                product_id: product ? product.id : null
            };
        }

        console.log('handleMenuItemsProductChange: producer', producer);
        console.log('handleMenuItemsProductChange: product', product);
        console.log('currentProducts', currentProducts);

        setFormValues({
            ...formValues,
            products: currentProducts
        });
    }

    const findCategory = (categoryId) => {
        return categories.find((element) => {
            return element.id === categoryId;
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form}>
                <h1 className={classes.h1}>Szczegóły zestawu</h1>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Nazwa"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={formValues.name}
                    onChange={handleInputChange}
                    error={hasError('name', errors)}
                    helperText={
                        hasError('name', errors) ? errors.name[0] : null
                    }
                />

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Kategoria</InputLabel>
                    <Select
                        id="category_id"
                        name="category_id"
                        label={"Kategoria"}

                        value={(formValues && formValues.category_id) ? formValues.category_id : ''}
                        onChange={handleInputCategoryChange}

                        error={hasError('category_id', errors)}
                        helperText={
                            hasError('category_id', errors) ? errors.category_id[0] : null
                        }
                    >
                        <MenuItem value="">
                            Wybierz kategorię
                        </MenuItem>

                        {categories && categories.map(categoryItem => {
                            return (
                                <MenuItem value={categoryItem.id} key={categoryItem.id}>
                                    {categoryItem.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {formValues && formValues.category_id && formValues.category.type == 'pv' && (
                    <Box>
                        <h1 className={classes.h1}>Panel fotowoltaiczny</h1>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="first_producer_name"
                            label="Producent"
                            name="first_producer_name"
                            autoComplete="first_producer_name"
                            autoFocus
                            value={formValues.first_producer_name}
                            onChange={handleInputChange}
                            error={hasError('first_producer_name', errors)}
                            helperText={
                                hasError('first_producer_name', errors) ? errors.first_producer_name[0] : null
                            }
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="first_product_name"
                            label="Model"
                            name="first_product_name"
                            autoComplete="first_product_name"
                            autoFocus
                            value={formValues.first_product_name}
                            onChange={handleInputChange}
                            error={hasError('first_product_name', errors)}
                            helperText={
                                hasError('first_product_name', errors) ? errors.first_product_name[0] : null
                            }
                        />
                        {/*<ProductFields
                            categoryType={"pv"}
                            product={(formValues.products && formValues.products.length >= 1 && formValues.products[0]) ? formValues.products[0].product : null}
                            producer={(formValues.products && formValues.products.length >= 1 && formValues.products[0]) ? formValues.products[0].producer : null}
                            productType={1}
                            errors={errors}
                            onChange={(producer, product) => handleMenuItemsProductChange(1, producer, product)}/>*/}

                        <h1 className={classes.h1}>Inwerter</h1>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="second_producer_name"
                            label="Producent"
                            name="second_producer_name"
                            autoComplete="second_producer_name"
                            autoFocus
                            value={formValues.second_producer_name}
                            onChange={handleInputChange}
                            error={hasError('second_producer_name', errors)}
                            helperText={
                                hasError('second_producer_name', errors) ? errors.second_producer_name[0] : null
                            }
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="second_product_name"
                            label="Model"
                            name="second_product_name"
                            autoComplete="second_product_name"
                            autoFocus
                            value={formValues.second_product_name}
                            onChange={handleInputChange}
                            error={hasError('second_product_name', errors)}
                            helperText={
                                hasError('second_product_name', errors) ? errors.second_product_name[0] : null
                            }
                        />
                        {/*<ProductFields
                            categoryType={"pv"}
                            product={(formValues.products && formValues.products.length === 2 && formValues.products[1]) ? formValues.products[1].product : null}
                            producer={(formValues.products && formValues.products.length === 2 && formValues.products[1]) ? formValues.products[1].producer : null}
                            productType={2}
                            errors={errors}
                            onChange={(producer, product) => handleMenuItemsProductChange(2, producer, product)}/>*/}
                    </Box>
                )}

                {/*{formValues && formValues.category_id && formValues.category.type == '123456789' && formValues.products && (
                    <Box>
                        {(hasError('products', errors) ? errors.products[0] : null) && (
                            <Box>{errors.products[0]}</Box>
                        )}
                    </Box>
                )}
*/}
                {formValues && formValues.category_id && (
                    <Box>
                        <h1 className={classes.h1}>Oferty zestawu</h1>
                        <p>Określ ilości produktów w ofertach zestawu wraz z najważniejszymi parametrami.</p>

                        {(hasError('menu_items', errors) ? errors.menu_items[0] : null) && (
                            <Box>{errors.menu_items[0]}</Box>
                        )}

                        <MenuItemFields category={findCategory(formValues.category_id)} formValues={formValues} errors={errors} onChange={handleMenuItemsFieldsChange}/>
                    </Box>
                )}

                {/*<div style={{marginTop: 20}}>{JSON.stringify(formValues.products)}</div>*/}


                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </div>
    );
}

export default EditMenuCollectionForm;
