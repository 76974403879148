import React, {useState} from "react";
import {useUser} from "../../selector";
import HeaderBar from "../../components/HeaderBar";
import ajaxClient from "../../services/ajaxClient";
import Container from "@material-ui/core/Container";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getQuery, parseQuery} from "../../services/localizationService";
import Footer from "../Footer";
import Background from '../../assets/bg.png';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loader from "../../components/Loader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";

import {DataGrid} from '@material-ui/data-grid';
import AdminInvite from "../../components/AdminInvite";
import {Paper} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        marginBottom: '15px'
    },
}));

const columns = [
    {field: 'id', headerName: 'ID', width: 90},
    {
        field: 'name',
        headerName: 'Nazwa',
        width: 150
    },
    {
        field: 'email',
        headerName: 'email',
        width: 150
    },
    {
        field: 'phone',
        headerName: 'Telefon',
        type: 'text',
        width: 100
    },
    {
        field: 'address',
        headerName: 'Adres',
        type: 'text',
        width: 250
    },
    {
        field: 'Telefon',
        headerName: 'Telefon',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) =>
            console.log('params', params) +
            params.row.user ? params.row.user.phone : '',
    },
    {
        field: 'created_at',
        headerName: 'Data utworzenia',
        type: 'date',
        width: 150
    },
];

function AdminCatalogs(props) {
    const classes = useStyles();

    const query = props.query || '';

    console.log('[query]', query);
    console.log('[parseQuery]', parseQuery(query));

    const user = useUser();

    const [catalogs, setCatalogs] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [showMap, setShowMap] = useState(parseQuery(query).showMap);
    const [address, setAddress] = useState(parseQuery(query).address);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    React.useEffect(() => {
        if (formValues) {

            async function fetchCatalogs() {
                const response = await ajaxClient().get(`catalogs?limit=1000&` + getQuery(formValues));
                setCatalogs(response.data.data);
            }

            fetchCatalogs();
        }
    }, [formValues]);

    function handleClick(event) {
        event.preventDefault();
    }

    function handleMap(event) {
        setShowMap(event.target.checked);
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/admin/catalogs" titleBar=""/>
            <Container className={classes.container}>
                <Box className={classes.items}>
                    <Box pt={2} pb={2}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" onClick={handleClick}>
                                Strona główna
                            </Link>

                            <Link
                                color="textPrimary"
                                href="/admin/catalogs"
                                to="/admin/catalogs"
                                /*onClick={() => navigate("/catalogs")}*/
                            >
                                Panel administracyjny
                            </Link>

                            <Link
                                color="textPrimary"
                                href="/admin/catalogs"
                                to="/admin/catalogs"
                                aria-current="page"
                            >
                                Zarządzanie katalogami
                            </Link>
                        </Breadcrumbs>
                    </Box>
                    <Paper className={classes.paper}>
                        <Box p={2}>
                            <Typography component="h5" variant="h5">Szybkie linki</Typography>
                            <br/>
                            <AdminInvite catalog={null}/>
                        </Box>
                    </Paper>

                    <br/>

                    {/*<Paper className={classes.paper} style={{height: 600, width: '100%'}}>
                        <Box p={2}>
                            <Typography component="h5" variant="h5">Klienci</Typography>

                            {!catalogs && (
                                <Loader>Trwa ładowanie...</Loader>
                            )}

                            {catalogs && (
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={catalogs}
                                        columns={columns}
                                        rowHeight={38}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                    />
                                </div>
                            )}
                        </Box>
                    </Paper>*/}

                </Box>
            </Container>
        </div>
    );
}

export default AdminCatalogs;
