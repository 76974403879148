import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AddMenuCollectionForm from "../Forms/AddMenuCollectionForm";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },
}));

export default function AddMenuCollectionDialog({open = false, onClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [submit, setSubmit] = React.useState(false);

    const handleClose = (result) => {
        console.log('ZAMKNIETO BO ZAPISANO FORME', result);
        setSubmit(false);
        onClose(result);
    };

    const handleButtonClose = () => {
        console.log('ZAMKNIETO BO NIE ZAPISANO FORMY');
        setSubmit(false);
        onClose();
    };

    const handleError = () => {
        setSubmit(false);
    };

    const triggerSubmit = () => {
        setSubmit(true);
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleButtonClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Utwórz zestaw"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wpisz nazwę i wybierz kategorię zestawu.
                    </DialogContentText>
                    <AddMenuCollectionForm onSuccess={handleClose} onError={handleError} triggerSubmit={submit}/>
                </DialogContent>
                <DialogActions className={classes.fullButtons}>
                    <Button autoFocus onClick={handleButtonClose} color="secondary" variant="contained" size="large">
                        Anuluj
                    </Button>
                    <Button onClick={triggerSubmit} color="primary" variant="contained" autoFocus size="large">
                        Utwórz
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
