import React from "react";
import CatalogListItem from "../Catalogs/CatalogListItem";
import Grid from "@material-ui/core/Grid";
import Loader from "../Loader";
import OffersListItem from "./OffersListItem";
import {CheckCircleRounded} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {assetsPath} from "../../services/mainService";

const useStyles = makeStyles((theme) => ({
    typo: {
        marginTop:'24px',
        textAlign: "center",
        fontSize:'16px',
        color:'#757575',
    },
    box: {
        border: '1px solid #DDDDDD',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10
        },
    },
    img :{

            maxWidth:"300px",
            maxHeight:"300px",
            margin:"auto",
            display:"block",
            padding:"24px 0px 0px",

    }
}));


function OffersList({menuItems}) {
    const classes = useStyles();

    return (
        <>
            {menuItems.length > 0 && (
                <Grid
                    container
                    spacing={4}
                    direction="row"
                    alignItems="flex-start"
                >
                    {menuItems && menuItems.map(menuItem => {
                        return (
                            <Grid item md={12} sm={12} xs={12} key={menuItem.id}>
                                <OffersListItem
                                    menuItem={menuItem}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {menuItems.length === 0 && (
                <Box mt={5} class={classes.box} display={"flex"} justifyContent={"center"}>
                   <div>
                       <Typography component="p" className={classes.typo}>
                        Brak usługodawców spełniających Twoje kryteria wyszukiwania.
                    </Typography>

                            <img src={assetsPath('noServices.png')} className={classes.img}/>

                   </div>
                </Box>
            )}
        </>
    );
}

export default OffersList;
