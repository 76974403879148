import React, {useState} from "react";
import ajaxClient from "../../services/ajaxClient";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {navigate} from "@reach/router";
import {getOffersQueryLink, getQueryLink, parseQuery} from "../../services/localizationService";
import {APP_SITE, FONTS} from "../../Theme";
import MenuItem from "@material-ui/core/MenuItem";
import {TextField} from "@material-ui/core";
import CategoryIcon from "./CategoryIcon";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    categories: {
        paddingBottom: theme.spacing(2),
        '& a': {
            display: 'block',
            font: 'normal normal normal 16px/18px ' + FONTS.fontFamily,
            letterSpacing: '0.47px',

            paddingTop: '5px',
            paddingBottom: '5px'
        },
    },

    categoriesIcons: {
        display: "block",
        maxWidth: "100%",
        overflow: "hidden"
    },

    categoriesBox: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',

        '&::-webkit-scrollbar-thumb': {
            borderRadius: 6,
            backgroundColor: 'red',
            minHeight: 6,
            maxHeight: 6
        },

        '&:hover': {
            overflowX: 'auto',
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
        }
    },
    button: {
        marginRight: theme.spacing(1),
        flexShrink: 0,
    },
    current: {
        color: 'black',
        fontWeight: 'bold'
    },
    select: {
        width: '100%',
        minWidth: 160,
        maxWidth: 160
    },
}));


function CatalogCategoriesFilter({query = null, type = "catalogs"}) {
    const classes = useStyles();

    console.log('[query category]', query);

    const [categories, setCategories] = useState([]);
    const [formValues, setFormValues] = useState(null);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);

    const getQueryLinkByType = (data) => {
        console.log('getQueryLink(data)', getQueryLink(data));

        if (type === "catalogs") {
            return getQueryLink(data);
        } else {
            return getOffersQueryLink(data);
        }
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        navigate(process.env.PUBLIC_URL + getQueryLinkByType({...formValues, category: value}));
        //navigate(process.env.PUBLIC_URL + getQueryLinkByType({category: value}));

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function getHref(category) {
        console.log(category, 'link', process.env.PUBLIC_URL + getQueryLinkByType({...formValues, category: category.slug}));
        return process.env.PUBLIC_URL + getQueryLinkByType({...formValues, category: category.slug})
    }

    return (
        <section className={APP_SITE === 'jakieoze' ? classes.categories : classes.categoriesIcons}>
            {APP_SITE === 'jakieoze' && (
                <TextField
                    select
                    id="category"
                    name="category"
                    label={"Kategoria"}
                    variant={"outlined"}

                    value={(formValues && formValues.category) ? formValues.category : ''}
                    onChange={handleInputChange}
                    className={classes.select}
                    size="small"
                >
                    <MenuItem value="">
                        Wybierz
                    </MenuItem>

                    {categories && categories.map(categoryItem => {
                        return (
                            <MenuItem value={categoryItem.name} key={categoryItem.id}>
                                {categoryItem.name}
                            </MenuItem>
                        );
                    })}
                </TextField>
            )}

            {APP_SITE === 'paramedycy' && (
                    <div className={classes.categoriesBox}>
                        {categories && categories.map(categoryItem => {
                            return (
                                <CategoryIcon href={getHref(categoryItem)} category={categoryItem} />
                                /*<MenuItem value={categoryItem.name} key={categoryItem.id}>
                                    {categoryItem.name}
                                </MenuItem>*/
                            );
                        })}
                    </div>
            )}

        </section>
    );
}

export default CatalogCategoriesFilter;
