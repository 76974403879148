import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MessageForm from "../Forms/MessageForm";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "../../../assets/icons/close.svg";
import {assetsPath} from "../../../services/mainService";
import {maxWidth} from "@material-ui/system";
import {APP_SITE, FONTS} from "../../../Theme";

const useStyles = makeStyles((theme) => ({
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },
    bot:{
        borderColor:'#ECECEC',
        backgroundColor:'#ECECEC',
        color: '#757575',
        boxShadow:'none',
    },

    dialogTitle: {
        "& > h2": {
            display: "flex",
            justifyContent: "center!important",
            alignItems: "center",
            fontSize:'20px',
            padding:'0px'
        },
    },
    pthank:{
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: '16px',
        lineHeight: '150%',
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        color: "#757575",
        padding:"0px 24px 24px"
    },
    plugicon:{
        maxWidth:"85px",
        maxHeight:"146px",
        margin:"auto",
        display:"block",
        padding:"24px",
    }
}));

export default function MessageDialog({open = false, data = null, onClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [submit, setSubmit] = React.useState(false);
    const [thankYou, setThankYou] = React.useState(false);

    const handleClose = () => {
        setSubmit(false);
        setThankYou(true);
        //onClose();
    };

    const closeModal = () => {
        onClose();
        setThankYou(false);
    }

    const handleError = () => {
        setSubmit(false);
    };

    const triggerSubmit = () => {
        setSubmit(true);
    };

    if (!data) {
        return <></>
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={closeModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
                    {"Zapytaj o ofertę"}
                    {/*<IconButton onClick={() => closeModal()}>
                        <img src={CloseIcon}/>
                    </IconButton>*/}
                </DialogTitle>

                <DialogContent>
                    {/*<DialogContentText>
                        Skontaktuj się z usługodawcą
                    </DialogContentText>*/}
                    {thankYou && (
                        <div>
                            <img src={assetsPath('plugicon.png')} className={classes.plugicon}/>
                            {APP_SITE == 'jakieoze' && (
                            <div  className={classes.pthank}>
                                Twoja wiadomość została wysłana. Na podany adres email prześlemy kopię wiadomości. Czekaj na kontakt od instalatora.
                            </div>)}
                            {APP_SITE == 'paramedycy' && (
                                <div  className={classes.pthank}>
                                    Twoja wiadomość została wysłana. Na podany adres email prześlemy kopię wiadomości. Czekaj na kontakt.
                                </div>)}
                        </div>
                    )}

                    {!thankYou && (
                        <>
                            <MessageForm onSuccess={handleClose} onError={handleError} triggerSubmit={submit} catalog={data.catalog} menuItem={data.menuItem}/>
                        </>
                    )}
                </DialogContent>
                {!thankYou && (
                    <DialogActions className={classes.fullButtons}>
                        <Button className={classes.bot} autoFocus onClick={closeModal} color="secondary" variant="contained" size="large">
                            Anuluj
                        </Button>
                        <Button onClick={triggerSubmit} color="primary" variant="contained" autoFocus size="large" disabled={submit}>
                            {!submit ? (
                                <Box>Wyślij</Box>
                            ) : (<Box>Wysyłanie</Box>)}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}
