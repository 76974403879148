import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderBar from "../../components/HeaderBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Typography} from "@material-ui/core";
import {getProfile} from "../../services/userService";
import Grid from "@material-ui/core/Grid";
import CatalogMenuItem from "../../components/Profile/CatalogMenuItem";
import Button from "@material-ui/core/Button";
import AddMenuItemDialog from "../../components/Profile/Dialogs/AddMenuItemDialog";
import CatalogDialog from "../../components/Profile/Dialogs/CatalogDialog";
import EditMenuItemDialog from "../../components/Profile/Dialogs/EditMenuItemDialog";
import CatalogHeader from "../../components/Profile/CatalogHeader";
import AddMenuCollectionDialog from "../../components/Profile/Dialogs/AddMenuCollectionDialog";
import EditMenuCollectionDialog from "../../components/Profile/Dialogs/EditMenuCollectionDialog";
import {deleteMenuCollection, deleteMenuItem} from "../../services/catalogService";
import {showSuccessSnackbar} from "../../actions/ui";
import {useDispatch} from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import {navigate} from "@reach/router";
import Auth from "./Auth";
import RemoveIcon from "@material-ui/icons/Remove";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import DeliveryDialog from "../../components/Profile/Dialogs/DeliveryDialog";
import CompanyDialog from "../../components/Profile/Dialogs/CompanyDialog";
import OpeningHours from "../../components/Profile/Dialogs/OpeningHours";
import Paper from "@material-ui/core/Paper";
import GalleryDialog from "../../components/Profile/Dialogs/GalleryDialog/GalleryDialog";
import {FONTS} from "../../Theme";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: '1px solid #DDDDDD',
        display: 'flex',
        marginTop: '16px',
        borderRadius: '6px',

    },
    buttons: {
        marginTop:"16px",
        '& > *': {
            margin: theme.spacing(1),

            [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: 0,
                marginTop: theme.spacing(2),
            },
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2)
    },

    collectionName: {
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "18px",
        lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        color: "#06106D"
    },
    t:{
        fontFamily: FONTS.fontFamily,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '150%',
        color:'#757575',
        padding: '32px 24px',
    }

    /*    ,
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        marginTop: theme.spacing(2)
    },*/
}));

function Menu(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [catalogs, setCatalogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [user, setUser] = useState(null);

    const [openDialog, setOpenDialog] = useState({
        'catalog': {
            open: false,
            data: null
        },
        'gallery': {
            open: false,
            data: null
        },
        'delivery': {
            open: false,
            data: null
        },
        'company': {
            open: false,
            data: null
        },
        'addMenuItem': {
            open: false,
            data: null
        },
        'editMenuItem': {
            open: false,
            data: null
        },
        'addMenuCollection': {
            open: false,
            data: null
        },
        'editMenuCollection': {
            open: false,
            data: null
        },
        'deleteMenuCollection': {
            open: false,
            data: null
        },
        'deleteMenuItem': {
            open: false,
            data: null
        },
        'openingHours': {
            open: false,
            data: null
        }
    });

    React.useEffect(() => {
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            setUser(response.data);
            var user = response.data;

            if (response.data && !response.data.catalog) {
                console.log('no catalog');
                handleOpenDialog('catalog');
                //navigate("/profile/menu/empty");
                //return <Redirect to="/profile/menu/empty" noThrow/>;
            }
        })
    }

    function handleOpenDialog(name, data = null) {
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

        console.log('openAddDialog', name, data);
    }

    function handleCloseDialog(name, result) {
        console.log('handleCloseDialog', name);

        reloadUser();

        if (name === 'addMenuCollection' && result) {
            setOpenDialog({
                ...openDialog,
                'addMenuCollection': {
                    ...openDialog.addMenuCollection,
                    open: false
                },
                'editMenuCollection': {
                    ...openDialog.editMenuCollection,
                    open: true,
                    data: result
                }
            });
        } else {
            setOpenDialog({
                ...openDialog,
                [name]: {
                    ...openDialog[name],
                    open: false
                }
            });
        }
    }

    function handleSuccessDialog(name, result) {
        console.log('handleCloseDialog', name);

        getProfile().then(response => {
            setUser(response.data);
            var user = response.data;

            if (response.data && response.data.catalog) {
                setOpenDialog({
                    ...openDialog,
                    [name]: {
                        open: true,
                        data: user.catalog
                    }
                });
            }
        })
    }

    function handleDeleteMenuItem(menuItem) {
        /*dispatch(showSuccessSnackbar("Element został usunięty."));
        reloadUser();
        return;*/
        deleteMenuItem(menuItem).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                dispatch(showSuccessSnackbar(response.data.error.delete));
                reloadUser();
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                reloadUser();
            }
        });
    }

    function handleDeleteMenuCollection(collection) {
        deleteMenuCollection(collection).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                dispatch(showSuccessSnackbar(response.data.error.delete));
                reloadUser();
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                reloadUser();
            }
        });
        console.log('delete collection', collection);
    }

    return (
        <div>
            <HeaderBar routePath="/profile/menu" titleBar="Zarządzaj menu"/>
            <Container component="main">
                <Auth>
                    <CatalogDialog open={openDialog.catalog.open} data={openDialog.catalog.data}
                                   onClose={() => handleCloseDialog('catalog')}/>
                    <GalleryDialog open={openDialog.gallery.open} data={openDialog.gallery.data}
                                   onClose={() => handleCloseDialog('gallery')}
                                   onSuccess={() => handleSuccessDialog('gallery')}/>
                    <DeliveryDialog open={openDialog.delivery.open} data={openDialog.delivery.data}
                                    onClose={() => handleCloseDialog('delivery')}/>
                    <CompanyDialog open={openDialog.company.open} data={openDialog.company.data}
                                   onClose={() => handleCloseDialog('company')}/>
                    <OpeningHours open={openDialog.openingHours.open} data={openDialog.openingHours.data}
                                  onClose={() => handleCloseDialog('openingHours')}/>

                    <AddMenuItemDialog open={openDialog.addMenuItem.open} data={openDialog.addMenuItem.data}
                                       onClose={() => handleCloseDialog('addMenuItem')}/>

                    <AddMenuCollectionDialog open={openDialog.addMenuCollection.open}
                                             data={openDialog.addMenuCollection.data}
                                             onClose={(result) => handleCloseDialog('addMenuCollection', result)}/>
                    <EditMenuCollectionDialog open={openDialog.editMenuCollection.open}
                                              data={openDialog.editMenuCollection.data}
                                              onClose={() => handleCloseDialog('editMenuCollection')}/>

                    <ConfirmDialog
                        title="Usuń zestaw"
                        open={openDialog.deleteMenuCollection.open}
                        data={openDialog.deleteMenuCollection.data}
                        setOpen={(open) => handleCloseDialog('deleteMenuCollection', open)}
                        onConfirm={handleDeleteMenuCollection}
                    >
                        Czy na pewno chcesz usunąć wybrany zestaw?
                    </ConfirmDialog>

                    <ConfirmDialog
                        title="Usuń ofertę w zestawie"
                        open={openDialog.deleteMenuItem.open}
                        data={openDialog.deleteMenuItem.data}
                        setOpen={(open) => handleCloseDialog('deleteMenuItem', open)}
                        onConfirm={handleDeleteMenuItem}
                    >
                        Czy na pewno usunąć ofertę?
                    </ConfirmDialog>

                    <CssBaseline/>
                    <div className={classes.paper}>

                        {user && user.catalog && (
                            <div>
                                <CatalogHeader catalog={user.catalog} onClick={() => handleOpenDialog('catalog')}/>

                                <Grid container justify="flex-end" className={classes.buttons}>
                                    <Button variant="outlined" color="primary"
                                            onClick={() => navigate(process.env.PUBLIC_URL + user.catalog.url)}>PODGLĄD
                                        PROFILU</Button>

                                    {/*
                                    <Button variant="contained" color="secondary" onClick={() => handleOpenDialog('company')}>
                                        Ewidencja
                                    </Button>
*/}
                                    <Button variant="outlined" color="default"
                                            onClick={() => handleOpenDialog('openingHours')}>
                                        GODZINY OTWARCIA
                                    </Button>

                                    <Button variant="outlined" color="default"
                                            onClick={() => handleOpenDialog('delivery')}>
                                        ZASIĘG I ZAKRES USŁUG
                                    </Button>

                                    <Button variant="outlined" color="default"
                                            onClick={() => handleOpenDialog('catalog')}>
                                        DANE O FIRMIE
                                    </Button>

                                    <Button variant="outlined" color="default"
                                            onClick={() => handleOpenDialog('addMenuCollection')}>
                                        DODAJ ZESTAW
                                    </Button>

                                    <Button variant="outlined" color="default"
                                            onClick={() => handleOpenDialog('gallery', user.catalog)}>
                                        GALERIA
                                    </Button>

                                    {(1 == 2) && (
                                        <Button variant="outlined" color="default"
                                                onClick={() => handleOpenDialog('addMenuItem')} color="primary">
                                            Dodaj produkt
                                        </Button>
                                    )}
                                </Grid>

                                {user && user.collections && user.collections.map(collection => {
                                    return (
                                        <Grid container key={"collection" + collection.id}>
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}>
                                                    <Box display={"flex"} justifyContent={"space-between"}>
                                                        <Typography gutterBottom variant="h5" component="h1"
                                                                    className={classes.collectionName}>
                                                            {collection.name}
                                                        </Typography>
                                                        <Box className={classes.buttons}>
                                                            <Button size="small" variant="contained"
                                                                    aria-label="Edytuj zestaw"
                                                                    onClick={() => handleOpenDialog('editMenuCollection', collection)}>
                                                                EDYTUJ ZESTAW
                                                            </Button>

                                                            <Button size="small" variant="contained"
                                                                    aria-label="Usuń zestaw"
                                                                    onClick={() => handleOpenDialog('deleteMenuCollection', collection)}>
                                                                USUŃ ZESTAW
                                                            </Button>
                                                        </Box>
                                                    </Box>

                                                    {user.catalog.menu_items && user.catalog.menu_items.filter(menuItem => menuItem.menu_collection_id === collection.id).map(menuItem => {
                                                        return (

                                                            <Grid item md={12} sm={12} xs={12}
                                                                  key={"menuItem" + menuItem.id}>
                                                                <CatalogMenuItem menuItem={menuItem}
                                                                                 collection={collection}
                                                                                 onClick={() => handleOpenDialog('editMenuCollection', collection)}
                                                                                 onClickDelete={() => handleOpenDialog('deleteMenuItem', menuItem)}
                                                                />
                                                            </Grid>
                                                        )
                                                    })}

                                                    {user.catalog.menu_items && !user.catalog.menu_items.some(menuItem => menuItem.menu_collection_id === collection.id) && (
                                                        <Box className={classes.root}>
                                                        <Grid key={"menuItem" + collection.id}>
                                                            <Typography className={classes.t}>Aktualnie nie masz dodanych ofert w tym zestawie.</Typography>
                                                        </Grid>
                                                        </Box>
                                                    )}

                                                </Paper>
                                            </Grid>

                                        </Grid>
                                    )
                                })}

                                {user && user.collections && user.collections.length === 0 && (
                                    <Box>Brak dodanych ofert</Box>
                                )}

                            </div>

                        )}
                    </div>
                </Auth>
            </Container>
        </div>

    );
}

export default Menu;
