import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Rating from '@material-ui/lab/Rating/Rating';
import {FONTS} from "../../Theme";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex"
    },
    rating_stars: {
        fontSize: "19px",
        marginRight: "0.5rem",
        lineHeight: "1.1rem"
    },
    rating_text: {
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "130%",
        display: "flex",
        alignItems: "center",
        color: "#06106D",
        marginLeft: 5
    },
    rating_text_muted: {
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "130%",
        display: "flex",
        alignItems: "center",
        marginLeft: 5,
        fontWeight: 500,
        color: "#757575",
    },
    button: {
        [theme.breakpoints.down('md')]: {
            width: "100%"
        },
    }
}));

export default function RatingInfo({rating = 0, numVotes = 0, isClickable = false, onStarsClick, onClick, max = 5}) {
    const classes = useStyles();

    return (<div onClick={onClick}>
        <div className={classes.container}>
            <Rating className={classes.rating_stars}
                size='small'
                readOnly={!isClickable}
                value={rating}
                onClick={(event) => onStarsClick(event)}
                max={max}
                precision={0.1}
            />
            <div className={classes.rating_text}>{parseFloat(rating).toFixed(1)}</div>
            {numVotes >= 0 && (
                <div className={classes.rating_text_muted}>({numVotes})</div>
            )}
        </div></div>)
}
