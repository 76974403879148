import {useUser} from "../../selector";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import HeaderBar from "../../components/HeaderBar";
import {showSuccessSnackbar} from "../../actions/ui";
//import {redirectTo} from "@reach/router/index";
import {navigate} from "@reach/router";
import {useFetchProfile} from "../../actions";
import {updateContact} from "../../services/userService";
import {hasError} from "../../services/validators";
import Auth from "./Auth";
import BoxLayout from "../../components/Layouts/BoxLayout";
import Footer from "../Footer";
import ajaxClient from "../../services/ajaxClient";
import Link from "@material-ui/core/Link";
import {isEmpty} from "lodash";
import {useQuery} from "../../services/localizationService";
import Box from "@material-ui/core/Box";
import {COLORS, FONTS} from "../../Theme";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    h1: {
        textAlign: 'center',
        font: ' normal normal bold 24px/24px ' + FONTS.fontFamily,
        letterSpacing: '0.23px',
        color: COLORS.secondary,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Contact() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [from, setFrom] = React.useState(useQuery().get('from'));

    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState({});
    const [phoneSet, setPhoneSet] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [phoneConfirmationSubmitted, setPhoneConfirmationSubmitted] = useState(false);
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const fetchProfile = useFetchProfile();
    const user = useUser();

    const phoneRegex = new RegExp('^([0-9\\s\\-\\+\\(\\)]*)$');

    React.useEffect(() => {
        fetchProfile();
    }, []);

    React.useEffect(() => {
        if (user.phone) {
            console.log('test');
            setPhone(user.phone);
        }
    }, [user]);

    function changePhone(e) {
        setPhone(e.target.value);
    }

    function changeConfirmationCode(e) {
        setConfirmationCode(e.target.value);
    }

    function handlePhoneSubmit(event) {
        console.log('SUBMIT', phone);
        event.preventDefault();

        submitPhone(phone);
    }

    function submitPhone(data) {

        if(!phoneRegex.test(data)){
            console.log('invalid phone number: ' + data);
            setErrors( 'phone',  'Invalid phone number' );
            dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
        } else {
            fetchTokenState(data);
        }

        /*updateContact(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {
                dispatch(showSuccessSnackbar("Telefon został zmieniony!"));
                //navigate('/profile/confirm-phone');
                fetchTokenState();
            }
        });*/
    }

    function fetchTokenState(phone) {
        ajaxClient().post("profile/checkOrCreateToken/" + user.id + "/" +phone)
            .then(function (response) {

                console.log('token status: ' + JSON.stringify(response));
                setPhoneSet(true);
                //setPhoneConfirmationSubmitted(response.data);
            })
            .catch(function (err) {
                if (isEmpty(err)) {
                    return;
                }
                console.log('token err: ' + JSON.stringify(err));
                if (err.message.includes('422')) {
                    navigate(process.env.PUBLIC_URL + '/profile/contact');
                } else {
                    console.log(JSON.stringify(err));
                }
                //    console.log(JSON.stringify(err));
            })
        ;
    }

    function changeConfirmationCode(e) {
        setConfirmationCode(e.target.value);
    }

    function handleConfirmationCodeSubmit(event) {
        event.preventDefault();
        confirmPhone(user.id, confirmationCode);
    }

    function confirmPhone(userId, confirmationCode) {
        /*ajaxClient().post("profile/confirmPhone", {
            'userId': user.id,
            'confirmationCode': user.confirmationCode
        })*/

        ajaxClient().post("profile/confirmPhone/" + userId + "/" + phone + "/" + confirmationCode)
            .then(response => {
                /*const payload = response.data;
                localStorage.session = response.data.token;
                dispatch({ type: "LOGIN", payload });*/
                setPhoneConfirmationSubmitted(true);

                if (from) {
                    navigate(process.env.PUBLIC_URL + '/order');
                }
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    setErrors(error.response.data);
                } else {
                    throw(error);
                }
            });
    }

    return (
        <Box>
            <HeaderBar routePath="/profile/phone" titleBar=""/>
            <BoxLayout>
                <Auth>
                    <CssBaseline/>
                    <Box>
                        {!phoneSet && (<form onSubmit={handlePhoneSubmit} className={classes.form}>
                            <h1 className={classes.h1}>Dane kontaktowe</h1>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="phone"
                                label="Telefon"
                                name="phone"
                                autoComplete="phone"
                                autoFocus
                                value={phone}
                                onChange={changePhone}
                                error={hasError('phone', errors)}
                                helperText={
                                    hasError('phone', errors) ? errors.phone[0] : null
                                }
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.submit}
                            >
                                Zmień
                            </Button>
                        </form>)}
                        {phoneSet && !phoneConfirmationSubmitted && (<form onSubmit={handleConfirmationCodeSubmit}>

                            {errors && (
                                <div>{errors.error}</div>
                            )}

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="confirmationCode"
                                label="Wpisz kod potwierdzenia"
                                name="confirmationCode"
                                autoComplete="confirmationCode"
                                autoFocus
                                value={confirmationCode}
                                onChange={changeConfirmationCode}
                                error={hasError('confirmationCode')}
                                helperText={
                                    hasError('confirmationCode') ? errors.confirmationCode[0] : null
                                }
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Potwierdź numer telefonu
                            </Button>


                        </form>)}

                        {phoneSet && phoneConfirmationSubmitted && (
                            <div className="welcomeSubscribe">
                                <div>Numer telefonu został potwierdzony</div>
                                <Link href="/" variant="body2">
                                    Powrót do strony głównej
                                </Link>
                            </div>
                        )}

                    </Box>

                </Auth>
            </BoxLayout>
            <Footer/>
        </Box>

    );
}

export default Contact;
