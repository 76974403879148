import {useUser} from "../../../selector";
import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import HeaderBar from "../../HeaderBar";
import {showSuccessSnackbar} from "../../../actions/ui";
import {Redirect} from "@reach/router";
import {useFetchProfile} from "../../../actions";
import {updateContact} from "../../../services/userService";
import {hasError} from "../../../services/validators";
import {updateCatalog} from "../../../services/catalogService";
import CardMedia from "@material-ui/core/CardMedia";
import GeoAutoCompleteInput from "../../GeoAutoCompleteInput";
import GeoMap from "../../GeoMap";
import {SignalCellularNull} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import FileUploader from "../../FileUploader";
import GeoStaticMap from "../../GeoStaticMap";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function CatalogForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const fetchProfile = useFetchProfile();
    const user = useUser();

    const fileInputRef = useRef(null);
    const [map, setMap] = React.useState(null);

    React.useEffect(() => {
        fetchProfile();
    }, []);

    React.useEffect(() => {
        if (user && user.catalog) {
            setFormValues(user.catalog);
        }
    }, [user]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    const handleAttacheInputFile = () => {
        fileInputRef.current.click();
    };

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleInputAddressChange(event, data) {
        console.log('[AC]: addressChange', data);

        setFormValues({
            ...formValues,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,

            city: data.city,
            state: data.state,
            country: data.country
        });
    }

    const handleInputFileChange = (event) => {
        const target = event.target;
        const name = target.name;

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        console.log('handleInputFileChange', event.target.files);

        reader.onload = function () {

            console.log('setFormValues', reader.result);

            setFormValues({
                ...formValues,
                [name]: reader.result
            });
        };
    };

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateCatalog(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    function handleMapLoaded(map) {
        console.log('handleMapLoaded', map);
        setMap(map)
    }

    if (!user || !user.name) {
        return <Redirect to="/login" noThrow/>;
    }

    return (
        <Box>
            <form onSubmit={handleSubmit} className={classes.form}>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Nazwa firmy"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={formValues.name || ''}
                            onChange={handleInputChange}
                            error={hasError('name', errors)}
                            helperText={
                                hasError('name', errors) ? errors.name[0] : null
                            }
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="description_short"
                            label="Krótko o wykonywanej działalności"
                            name="description_short"
                            autoComplete="description_short"
                            autoFocus
                            rows="12"
                            multiline={true}
                            value={formValues.description_short || ''}
                            onChange={handleInputChange}
                            error={hasError('description_short', errors)}
                            helperText={
                                hasError('description_short', errors) ? errors.description_short[0] : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box pt={2} pb={1}>
                            <GeoAutoCompleteInput
                                label={'Lokalizacja'}
                                defaultValue={formValues.address || ''}
                                onChange={handleInputAddressChange}
                                error={hasError('address', errors)}
                                helperText={
                                    hasError('address', errors) ? errors.address[0] : null
                                }
                                map={map}
                            />
                        </Box>

                        <Box pt={2} pb={1}>
                            <GeoStaticMap
                                id="google-map"
                                position={
                                    {
                                        lat: formValues.latitude,
                                        lng: formValues.longitude
                                    }
                                }
                                onMapLoaded={handleMapLoaded}
                                style={{width: '100%', height: '240px'}}/>
                        </Box>
                    </Grid>
                </Grid>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="description"
                    label="Wykonywane usług, o firmie"
                    name="description"
                    autoComplete="description"
                    autoFocus
                    rows="12"
                    multiline={true}
                    value={formValues.description || ''}
                    onChange={handleInputChange}
                    error={hasError('description', errors)}
                    helperText={
                        hasError('description', errors) ? errors.description[0] : null
                    }
                />

                <FileUploader
                    name="image"
                    accept={'.peg,.png,.jpg,.gif'}
                    type="file"
                    image={formValues.image && formValues.image !== '' ? formValues.image : formValues.image_filename_url}
                    title={'Wgraj logo firmy'}
                    subtitle={'Maksymalna wielkość pliku do 1 MB'}
                    onChange={handleInputFileChange}/>

                <Box my={1}>
                    <Divider variant="fullWidth"/>
                </Box>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </Box>
    );
}

export default CatalogForm;
